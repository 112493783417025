<template>
  <div class="page page-giftcode-index">
    <GiftcodesForYouPanel />
    <b-row>
      <div class="w-100">
        <hr/>
      </div>
    </b-row>
    <GamePickerPanel @click="onGameClick"/>
  </div>
</template>

<script>
import GiftcodesForYouPanel from "@/components/panels/giftcode/GiftcodesForYouPanel";
import GamePickerPanel from "@/components/panels/game/GamePickerPanel";

export default {
  name: "Index",
  components: {GiftcodesForYouPanel, GamePickerPanel},
  methods: {
    onGameClick(game) {
      this.$router.push({
        name: "GameGiftCodeIndex",
        params: {
          aliasUrl: game.aliasUrl
        }
      })
    }
  }
}
</script>

<style scoped>

</style>